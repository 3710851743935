import React, { Component } from "react";

import logo from '../assets/images/logo.png';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <img className="logo" src={logo} alt="ABC Logo" />

        <div className="showroom">
          <div className="desc">
            <h3>Site and showroom</h3>
            <p>N.Bacalso Ave., Corner V. Rama St., Cebu City <br />
              Contact us at: 0938-4000-000<br />
              Tel, no. 032-2381306</p>
          </div>
        </div>

        <div className="office">
          <h3>Office</h3>
          <p>1104 Insular Life Business Center<br />
            Mindanao Ave., cor. Biliran Road<br />
            Cebu Business Park, Cebu City 6000<br />
            Tel. No. 032-3441640</p>
        </div>

        <div className="media">
          <h3 className="icon-container">
            <a href="https://www.facebook.com/arctowersofficial" target="_blank"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/arctowersofficial" target="_blank"><i className="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UCFJXE6O4F3ex23k3aq0sB8Q" target="_blank"><i className="fab fa-youtube"></i></a>
          </h3>
          <p>For questions and inquiries, message us through<br />
            <a href="mailto:inquiry@abcprime.com.ph">inquiry@abcprime.com.ph</a>
          </p>
        </div>

        <div className="copyright">
          © ABC PRIME 2019
        </div>
      </footer>
    );
  }
}
export default Footer;
