import React, { Component } from 'react';
import axios from "axios";
import SweetAlert from 'sweetalert2-react';
import contactImg from '../assets/images/contact-image.jpg';

class Contact extends Component {

    state = {
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        sending: false,
        showAlert: false,
        showError: false,
        contactFormValidation: {
            firstname: false,
            lastname: false,
            email: false,
            emailInvalid: false
        }
    }

    submitMessage = async () => {
        if (this.state.sending) return;

        const validation = {
            firstname: !this.state.firstname.trim(),
            lastname: !this.state.lastname.trim(),
            email: !this.state.email.trim(),
            emailInvalid: !this.validateEmail(this.state.email)
        }

        this.setState({contactFormValidation: validation});
        if(validation.firstname || validation.lastname || validation.email || validation.emailInvalid) {
            return;
        }

        this.setState({ sending: true });

        const headers = new Headers();
        const fullname = this.state.firstname.trim() + " " + this.state.lastname.trim();
        const data = {
            email: this.state.email,
            fullName: fullname,
            message: this.state.message
        };
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        let res = await axios.post('http://116.93.111.245:8080/contact/save', data, { headers });

        console.log(res);
        if (res.data.code === "000") {
            this.setState({ firstname: '', lastname: '', email: '', message: '', showAlert: true });
        } else {
            this.setState({ showError: true });
        }

        this.setState({ sending: false });

    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        const { contactFormValidation } = this.state;

        return (
            <div className="contact-page main-content">
                <SweetAlert
                    show={this.state.showAlert}
                    title="Thank You!"
                    text="Our sales team will reach out to you shortly."
                    type="success"
                    onConfirm={() => this.setState({ showAlert: false })}
                />

                <SweetAlert
                    show={this.state.showError}
                    title="Oops!"
                    text="Something went wrong with our sevice. Please try again later."
                    type="error"
                    onConfirm={() => this.setState({ showError: false })}
                />

                <section className="image-title">
                    <img src={contactImg} alt="Development" />
                    <span className="title">Contact Us</span>
                </section>
                <section className="contact-us">
                    {/* <h2 className="section-title">Contact Us</h2> */}

                    <div className="content">
                        <div className="contact-form">
                            <form>
                                <div className="input-group">
                                    <label htmlFor="firstname">First Name</label>
                                    <input id="firstname" type="text" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value })} />
                                    { contactFormValidation.firstname ? <span className="error-text">* First name is required</span> : null }
                                </div>
                                <div className="input-group">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input id="lastname" type="text" value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value })} />
                                    
                                    { contactFormValidation.lastname? <span className="error-text">* Last name is required</span> : null }
                                </div>
                                <div className="input-group">
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email" id="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                    { contactFormValidation.email ? <span className="error-text">* Email address is required</span> : null }
                                    { !contactFormValidation.email && contactFormValidation.emailInvalid ? <span className="error-text">* Email format is wrong</span> : null }
                                </div>
                                <div className="input-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message" id="message" rows="4" value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea>
                                </div>
                                <div className="input-group">
                                    <div className="submit-container">
                                        <input type="button" id="submit" name="submit" value="Submit" onClick={this.submitMessage} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="contact-info">
                            <h3>Site and showroom</h3>
                            <p className="capital">n.bacalso ave., corner v. Rama st., Cebu City<br />
                                Contact us at: 0938-4000-000<br />
                                Tel, no. 032-2381306</p>

                            <h3>Office</h3>
                            <p className="capital">
                                1104 Insular Life Business Center Mindanao Ave., cor. <br />
                                Biliran Road Cebu Business Park, Cebu City 6000 <br />
                                Tel. No. 032-3441640
                            </p>

                            <p>
                                for questions and inquiries, message us through <br />
                                <a href="mailto:inquiry@abcprime.com.ph">inquiry@abcprime.com.ph</a>
                            </p>
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}

export default Contact;