import React, { Component } from 'react'
import mediaImg from '../assets/images/media-image.jpg';

// post images
import post1Img from '../assets/images/post-1.png';
import post2Img from '../assets/images/post-2.png';
import post3Img from '../assets/images/post-3.png';
import post4Img from '../assets/images/post-4.png';
import post5Img from '../assets/images/post-5.png';
import post6Img from '../assets/images/post-6.png';
import post7Img from '../assets/images/post-7.png';
import post8Img from '../assets/images/post-8.png';

const postData = [
    { img: post1Img, desc: "ARC Towers: First to promise money-back guarantee in the real estate category", link: "/images/ARC-Towers-First-to-promise-money-back-guarantee-in-the-real-estate-category.jpg" },
    { img: post2Img, desc: "A New landmark to rise at the heart of the city", link: "/images/A-New-landmark-to-rise-at-the-heart-of-the-city.jpg" },
    { img: post3Img, desc: "ARC TOWERS rise above the competition in the 7th PROPERTYGURU PH PROPERTY AWARDS", link: "/news/arc-towers-rise-above.html" },
    { img: post4Img, desc: "ARC TOWERS Scheduled to soar as Cebu Economy Skyrocket", link: "/news/arc-towers-scheduled-to-soar.html" },
    { img: post5Img, desc: "ABC Prime Offers money back guarantee", link: "/images/ABC-Prime-Offers-money-back-guarantee.jpg" },
    { img: post6Img, desc: "Rising demand to push property prices higher", link: "https://www.philstar.com/the-freeman/cebu-business/2019/04/02/1906648/rising-demand-push-property-prices-higher#MYy73rkyS3qxoyEY.99" },
    { img: post7Img, desc: "ARC TOWERS Showroom Launching and Ribbon Cutting Ceremony", link: "/news/arc-towers-showroom-launching.html" },
    { img: post8Img, desc: "ABC Prime sponsored the 8th GMM of Philres Mandaue Chapter" },
]

class Media extends Component {

    render() {
        return (
            <div className="media-page main-content">
                <section className="image-title">
                    <img src={mediaImg} alt="Development" />
                    <span className="title">Media Mileage</span>
                </section>
                <section className="posts">
                    {/* <h2 className="section-title left-aligned">PRESS RELEASE, ARTICLE & BLOG</h2> */}

                    {
                        postData.map((post, index) => {
                            return (
                                <div className="post">
                                    <div className="img-wrapper">
                                        <img src={post.img} alt={"post " + index} />
                                    </div>
                                    <div className="desc">
                                        <p>{post.desc}</p>
                                        <a href={post.link} target="_blank">Read More</a>
                                    </div>
                                </div>

                            );
                        })
                    }
                </section>
            </div>
        )
    }
}

export default Media;