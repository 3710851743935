import React, { Component } from 'react';

import developmentBG from '../assets/images/development-bg.jpg';
import towerImg from '../assets/images/tower.jpg';

const consultantData = [
    { name: "Architect :", value: "LPPA Design Group Inc." },
    { name: "Interior Designer :", value: "LPPA Design Group Inc." },
    { name: "Landscape Designer :", value: "Holtz + Design Landscape" },
    { name: "", value: "Architecture" },
    { name: "Marketing, Advertising & Branding :", value: "McCann World Group" },
    { name: "Quantity Surveying :", value: "Arcadis" },
    { name: "Design Concept & Master Planning :", value: "Broadway Malyan" },
    { name: "Project Development Consultant :", value: "Santos Knight Frank" },
    { name: "Foundation Company :", value: "Bislig Ventures Construction" },
    { name: "", value: "and Development Inc" },
    { name: "Project Management &", value: "" },
    { name: "Construction Management Team :", value: "Aranaz Construction" },
    { name: "", value: "Management Consultancy" },
]

const developmentData = [
    { name: "Development Type :", value: "Mixed Use Development"},
    { name: "Total Storey :", value: "22 Storey + Roof-deck"},
    { name: "Total Units :", value: "697 Units"},
    { name: "Gross Development Area :", value: "8,853 sq. m."},
    { name: "Parking :", value: "228 Parking Units"},
    { name: "Type of Residential Units :", value: "All Studio Type"},
    { name: "Target Date of Completion :", value: "December 2022"},
    { name: "Location :", value: "N. Bacalso Ave., corner V."},
    { name: "", value: "Rama St., Cebu City"},
]

class Development extends Component {
    render() {
        return (
            <div className="development-page main-content">
                <section className="image-title">
                    <img src={developmentBG} alt="Development" />
                    <span className="title">Our Development</span>
                </section>
                <section className="arc-towers">
                    <h2 className="section-title">Arc Towers</h2>
                    <div className="tower-details">
                        <img src={towerImg} alt="Tower" />
                        <div className="desc">
                            <p>Arc Towers is a neighborhood of green and urban spaces that allow for the creation of a new residential and commercial district. </p>
                            <p>This residential development is situated in proximity to mountains and seas, which serves as inspiration in creating a scheme that brings these two elements together, creating a lush and tropical urban forest setting. </p>
                            <p>It has central open space with an active frontage created by the retail spaces. </p>
                            <p>It embraces both city lifestyle and resort living to form a unique development and destination for tourists and locals.</p>
                        </div>
                    </div>

                    <div className="extra-info">
                        <div className="consultant">
                            <h2 className="section-title">Consultant</h2>
                            {
                                consultantData.map(row => {
                                    return (
                                        <div className="info-row">
                                            <div className="info-name">{row.name}</div>
                                            <div className="info-value">{row.value}</div>
                                        </div>
                                    );
                                })
                            }

                        </div>
                        <div className="development-details">
                            <h2 className="section-title">Development Details</h2>

                            {
                                developmentData.map(row => {
                                    return (
                                        <div className="info-row">
                                            <div className="info-name">{row.name}</div>
                                            <div className="info-value">{row.value}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}

export default Development;