import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import logo from "../assets/images/abc-logo.png";

const navRoutes = [
  {
    link: '/',
    name: 'Home'
  },
  {
    link: '/development',
    name: 'Development',
    sub: true
  },
  {
    link: '/media',
    name: 'Media Mileage'
  },
  {
    link: '/about',
    name: 'About'
  },
  {
    link: '/contact',
    name: 'Contact Us'
  },
]

class Header extends Component {

  state = {
    hideMenu: true
  }
  render() {
    return (
      <header className={"header " + (this.state.hideMenu ? "hide-items" : "show-items")}>
        <img className="logo" src={logo} alt="ABC Prime Logo" />

        <nav>
          {
            // title attr is to make sure the width of the <a> stay the same when changing between bold and normal weight. the attr will be used in css.
            navRoutes.map((route, index) => {
              return <NavLink className={route.sub ? 'has-sub' : ''} key={index} exact={route.link === '/'} to={route.link} activeClassName="active" title={route.name}>
                {route.name}
                {route.sub ?
                  <div className="submenu">
                      <a href="http://www.google.com" onClick={() => window.open('http://arc.abcprime.com', '_blank').focus()}>Arc Tower</a>
                      <a href="#allproduct" class="disabled">All Product</a>
                  </div>
                  : null}
              </NavLink>
            })
          }
        </nav>

        <div className="res-menu" onClick={() => this.setState({ hideMenu: !this.state.hideMenu })}>
          <i class="fas fa-bars"></i>
        </div>
      </header>
    );
  }
}
export default Header;
