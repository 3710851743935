import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import Development from './pages/Development';
import Media from './pages/Media';
import About from './pages/About';
import Contact from './pages/Contact';

import '@fortawesome/fontawesome-free/css/all.css';
import './assets/sass/app.css';

function Topic({ match }) {
  return <h3>Requested Param: {match.params.id}</h3>;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/topics" component={Topic} />
        <Route path="/development" component={Development} />
        <Route path="/media" component={Media} />
        <Route path="/contact" component={Contact} />

        <Footer />
      </div>
    </Router>
  );
}

export default App;
