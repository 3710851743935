import React, { Component } from "react";

import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from 'react-slick';

import arrowArea from '../assets/svgs/arrow-area.svg';
import arrowDown from '../assets/svgs/arrow-down.svg';
import slide1 from '../assets/images/slide-1.jpg';
import slide2 from '../assets/images/slide-2.jpg';
import slide3 from '../assets/images/slide-3.jpg';
import awards from '../assets/images/awards.png';
import lppaLogo from '../assets/svgs/lppa.svg';
import broadwayLogo from '../assets/svgs/broadway-malyan.svg';
import arcadisLogo from '../assets/svgs/arcadis.svg';
import santosLogo from '../assets/svgs/santos.svg';

class Home extends Component {
    render() {
        const sliderSettings = {
            customPaging: (i) => {
                return <span className="slide-pagination-item">{i + 1}</span>
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
        };
        return (
            <div className="home-page main-content">
                <section className="welcome">
                    {/* <img className="quote" src={welcomeQuote} alt="Aspire Build Connect" /> */}
                    <div className="quote">
                        <div className="abc-svg-container">
                            <svg id="abc-svg" viewBox="0 0 764 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="A" d="M30.1392 1L38.9648 60.2896H23.1812L22.4121 49.6328H16.8823L15.9668 60.2896H0L7.83691 1H30.1392ZM21.9727 39.1226C21.1914 32.4087 20.4102 24.1201 19.6289 14.2568C18.0664 25.585 17.0898 33.8735 16.6992 39.1226H21.9727Z" fill="#F2910B" />
                                <path id="B" d="M264.35 1H279.73C284.589 1 288.263 1.37842 290.753 2.13525C293.268 2.89209 295.294 4.43018 296.833 6.74951C298.371 9.04443 299.14 12.7554 299.14 17.8823C299.14 21.3491 298.59 23.7661 297.492 25.1333C296.417 26.5005 294.281 27.5503 291.083 28.2827C294.647 29.0884 297.064 30.4312 298.334 32.311C299.604 34.1665 300.238 37.0229 300.238 40.8804V46.3735C300.238 50.3774 299.774 53.3438 298.847 55.2725C297.943 57.2012 296.491 58.5195 294.489 59.2275C292.487 59.9355 288.385 60.2896 282.184 60.2896H264.35V1ZM279.767 11.144V24.3276C280.426 24.3032 280.939 24.291 281.305 24.291C282.819 24.291 283.783 23.9248 284.198 23.1924C284.613 22.4355 284.821 20.2993 284.821 16.7837C284.821 14.9282 284.65 13.6343 284.308 12.9019C283.966 12.145 283.515 11.6689 282.953 11.4736C282.416 11.2783 281.354 11.1685 279.767 11.144ZM279.767 33.5562V50.1455C281.94 50.0723 283.319 49.7305 283.905 49.1201C284.516 48.5098 284.821 47.0083 284.821 44.6157V39.0859C284.821 36.5469 284.552 35.0088 284.015 34.4717C283.478 33.9346 282.062 33.6294 279.767 33.5562Z" fill="#F2910B" />
                                <path id="C" d="M527.293 27.0996H511.876V16.7725C511.876 13.7695 511.705 11.9019 511.363 11.1694C511.046 10.4126 510.325 10.0342 509.202 10.0342C507.933 10.0342 507.127 10.4858 506.785 11.3892C506.444 12.2925 506.273 14.2456 506.273 17.2485V44.7876C506.273 47.6685 506.444 49.5483 506.785 50.4272C507.127 51.3062 507.896 51.7456 509.093 51.7456C510.24 51.7456 510.985 51.3062 511.326 50.4272C511.693 49.5483 511.876 47.4854 511.876 44.2383V36.8042H527.293V39.1113C527.293 45.2393 526.854 49.585 525.975 52.1484C525.12 54.7119 523.204 56.958 520.225 58.8867C517.271 60.8154 513.621 61.7798 509.276 61.7798C504.759 61.7798 501.036 60.9619 498.106 59.3262C495.177 57.6904 493.236 55.4321 492.283 52.5513C491.331 49.646 490.855 45.2881 490.855 39.4775V22.1558C490.855 17.8833 491.002 14.6851 491.295 12.561C491.588 10.4126 492.454 8.34961 493.895 6.37207C495.36 4.39453 497.374 2.84424 499.937 1.72119C502.525 0.57373 505.491 0 508.836 0C513.377 0 517.125 0.878906 520.079 2.63672C523.033 4.39453 524.974 6.5918 525.902 9.22852C526.829 11.8408 527.293 15.918 527.293 21.46V27.0996Z" fill="#F2910B" />
                                <g id="spire" filter="url(#filter0_d)">
                                    <path d="M74.4287 19.1895H60.1099V14.7949C60.1099 12.7441 59.9268 11.438 59.5605 10.8765C59.1943 10.3149 58.584 10.0342 57.7295 10.0342C56.8018 10.0342 56.0938 10.4126 55.6055 11.1694C55.1416 11.9263 54.9097 13.0737 54.9097 14.6118C54.9097 16.5894 55.1782 18.0786 55.7153 19.0796C56.228 20.0806 57.6807 21.2891 60.0732 22.7051C66.9336 26.7822 71.2549 30.127 73.0371 32.7393C74.8193 35.3516 75.7104 39.563 75.7104 45.3735C75.7104 49.5972 75.21 52.71 74.209 54.7119C73.2324 56.7139 71.3281 58.3984 68.4961 59.7656C65.6641 61.1084 62.3682 61.7798 58.6084 61.7798C54.4824 61.7798 50.9546 60.9985 48.0249 59.436C45.1196 57.8735 43.2153 55.8838 42.312 53.4668C41.4087 51.0498 40.957 47.6196 40.957 43.1763V39.2944H55.2759V46.5088C55.2759 48.7305 55.4712 50.1587 55.8618 50.7935C56.2769 51.4282 56.9971 51.7456 58.0225 51.7456C59.0479 51.7456 59.8047 51.3428 60.293 50.5371C60.8057 49.7314 61.062 48.5352 61.062 46.9482C61.062 43.457 60.5859 41.1743 59.6338 40.1001C58.6572 39.0259 56.2524 37.2314 52.4194 34.7168C48.5864 32.1777 46.0474 30.3345 44.8022 29.187C43.5571 28.0396 42.5195 26.4526 41.6895 24.4263C40.8838 22.3999 40.481 19.812 40.481 16.6626C40.481 12.1216 41.0547 8.80127 42.2021 6.70166C43.374 4.60205 45.2539 2.96631 47.8418 1.79443C50.4297 0.598145 53.5547 0 57.2168 0C61.2207 0 64.6265 0.646973 67.4341 1.94092C70.2661 3.23486 72.1338 4.87061 73.0371 6.84814C73.9648 8.80127 74.4287 12.1338 74.4287 16.8457V19.1895Z" fill="white" />
                                    <path d="M80.5811 1.24512H96.1084C100.308 1.24512 103.53 1.57471 105.776 2.23389C108.047 2.89307 109.744 3.84521 110.867 5.09033C112.014 6.33545 112.783 7.84912 113.174 9.63135C113.589 11.3892 113.796 14.1235 113.796 17.8345V22.998C113.796 26.7822 113.406 29.541 112.625 31.2744C111.843 33.0078 110.403 34.3384 108.303 35.2661C106.228 36.1938 103.506 36.6577 100.137 36.6577H95.9985V60.5347H80.5811V1.24512ZM95.9985 11.3892V26.4771C96.438 26.5015 96.8164 26.5137 97.1338 26.5137C98.5498 26.5137 99.5264 26.1719 100.063 25.4883C100.625 24.7803 100.906 23.3276 100.906 21.1304V16.2598C100.906 14.2334 100.588 12.915 99.9536 12.3047C99.3188 11.6943 98.0005 11.3892 95.9985 11.3892Z" fill="white" />
                                    <path d="M133.645 1.24512V60.5347H118.228V1.24512H133.645Z" fill="white" />
                                    <path d="M139.834 1.24512H150.747C158.022 1.24512 162.942 1.52588 165.505 2.0874C168.093 2.64893 170.193 4.08936 171.804 6.40869C173.44 8.70361 174.258 12.3779 174.258 17.4316C174.258 22.0459 173.684 25.1465 172.537 26.7334C171.389 28.3203 169.131 29.2725 165.762 29.5898C168.813 30.3467 170.864 31.3599 171.914 32.6294C172.964 33.8989 173.611 35.0708 173.855 36.145C174.124 37.1948 174.258 40.1123 174.258 44.8975V60.5347H159.939V40.8325C159.939 37.6587 159.683 35.6934 159.17 34.9365C158.682 34.1797 157.375 33.8013 155.251 33.8013V60.5347H139.834V1.24512ZM155.251 11.3892V24.5728C156.985 24.5728 158.193 24.3408 158.877 23.877C159.585 23.3887 159.939 21.8384 159.939 19.2261V15.9668C159.939 14.0869 159.597 12.854 158.914 12.2681C158.254 11.6821 157.034 11.3892 155.251 11.3892Z" fill="white" />
                                    <path d="M180.264 1.24512H205.972V13.1104H195.681V24.353H205.312V35.6323H195.681V48.6694H206.997V60.5347H180.264V1.24512Z" fill="white" />
                                </g>
                                <g id="uild" filter="url(#filter1_d)">
                                    <path d="M340.802 0V39.624C340.802 44.1162 340.656 47.2778 340.363 49.1089C340.07 50.9155 339.203 52.7832 337.762 54.7119C336.322 56.6162 334.418 58.0688 332.05 59.0698C329.706 60.0464 326.935 60.5347 323.737 60.5347C320.197 60.5347 317.072 59.9487 314.362 58.7769C311.652 57.605 309.625 56.0791 308.282 54.1992C306.94 52.3193 306.146 50.3418 305.902 48.2666C305.658 46.167 305.536 41.7725 305.536 35.083V0H320.953V44.458C320.953 47.0459 321.088 48.7061 321.356 49.4385C321.649 50.1465 322.223 50.5005 323.077 50.5005C324.054 50.5005 324.677 50.1099 324.945 49.3286C325.238 48.5229 325.385 46.6431 325.385 43.689V0H340.802Z" fill="white" />
                                    <path d="M362.189 0V59.2896H346.771V0H362.189Z" fill="white" />
                                    <path d="M383.795 0V47.4243H393.17V59.2896H368.378V0H383.795Z" fill="white" />
                                    <path d="M396.942 0H408.478C415.924 0 420.953 0.341797 423.566 1.02539C426.202 1.70898 428.204 2.83203 429.572 4.39453C430.939 5.95703 431.793 7.70264 432.135 9.63135C432.477 11.5356 432.648 15.2954 432.648 20.9106V41.6748C432.648 46.9971 432.391 50.5615 431.879 52.3682C431.39 54.1504 430.524 55.5542 429.279 56.5796C428.033 57.5806 426.495 58.2886 424.664 58.7036C422.833 59.0942 420.074 59.2896 416.388 59.2896H396.942V0ZM412.36 10.144V49.1455C414.581 49.1455 415.948 48.7061 416.461 47.8271C416.974 46.9238 417.23 44.4946 417.23 40.5396V17.5049C417.23 14.8193 417.145 13.0981 416.974 12.3413C416.803 11.5845 416.412 11.0352 415.802 10.6934C415.192 10.3271 414.044 10.144 412.36 10.144Z" fill="white" />
                                </g>
                                <g id="onnect" filter="url(#filter2_d)">
                                    <path d="M568.054 35.9619C568.054 41.9189 567.908 46.1426 567.615 48.6328C567.346 51.0986 566.467 53.3569 564.978 55.4077C563.513 57.4585 561.523 59.0332 559.009 60.1318C556.494 61.2305 553.564 61.7798 550.22 61.7798C547.046 61.7798 544.189 61.2671 541.65 60.2417C539.136 59.1919 537.109 57.6294 535.571 55.5542C534.033 53.479 533.118 51.2207 532.825 48.7793C532.532 46.3379 532.385 42.0654 532.385 35.9619V25.8179C532.385 19.8608 532.52 15.6494 532.788 13.1836C533.081 10.6934 533.96 8.42285 535.425 6.37207C536.914 4.32129 538.916 2.74658 541.431 1.64795C543.945 0.549316 546.875 0 550.22 0C553.394 0 556.238 0.524902 558.752 1.57471C561.292 2.6001 563.33 4.15039 564.868 6.22559C566.406 8.30078 567.322 10.5591 567.615 13.0005C567.908 15.4419 568.054 19.7144 568.054 25.8179V35.9619ZM552.637 16.4795C552.637 13.7207 552.478 11.9629 552.161 11.2061C551.868 10.4248 551.245 10.0342 550.293 10.0342C549.487 10.0342 548.865 10.3516 548.425 10.9863C548.01 11.5967 547.803 13.4277 547.803 16.4795V44.165C547.803 47.6074 547.937 49.7314 548.206 50.5371C548.499 51.3428 549.158 51.7456 550.183 51.7456C551.233 51.7456 551.904 51.2817 552.197 50.354C552.49 49.4263 552.637 47.2168 552.637 43.7256V16.4795Z" fill="white" />
                                    <path d="M608.264 1.24512V60.5347H594.751L586.731 33.5815V60.5347H573.84V1.24512H586.731L595.374 27.9419V1.24512H608.264Z" fill="white" />
                                    <path d="M648.914 1.24512V60.5347H635.4L627.38 33.5815V60.5347H614.49V1.24512H627.38L636.023 27.9419V1.24512H648.914Z" fill="white" />
                                    <path d="M655.139 1.24512H680.847V13.1104H670.557V24.353H680.188V35.6323H670.557V48.6694H681.873V60.5347H655.139V1.24512Z" fill="white" />
                                    <path d="M722.339 27.0996H706.921V16.7725C706.921 13.7695 706.75 11.9019 706.409 11.1694C706.091 10.4126 705.371 10.0342 704.248 10.0342C702.979 10.0342 702.173 10.4858 701.831 11.3892C701.489 12.2925 701.318 14.2456 701.318 17.2485V44.7876C701.318 47.6685 701.489 49.5483 701.831 50.4272C702.173 51.3062 702.942 51.7456 704.138 51.7456C705.286 51.7456 706.03 51.3062 706.372 50.4272C706.738 49.5483 706.921 47.4854 706.921 44.2383V36.8042H722.339V39.1113C722.339 45.2393 721.899 49.585 721.021 52.1484C720.166 54.7119 718.25 56.958 715.271 58.8867C712.317 60.8154 708.667 61.7798 704.321 61.7798C699.805 61.7798 696.082 60.9619 693.152 59.3262C690.222 57.6904 688.281 55.4321 687.329 52.5513C686.377 49.646 685.901 45.2881 685.901 39.4775V22.1558C685.901 17.8833 686.047 14.6851 686.34 12.561C686.633 10.4126 687.5 8.34961 688.94 6.37207C690.405 4.39453 692.419 2.84424 694.983 1.72119C697.571 0.57373 700.537 0 703.882 0C708.423 0 712.17 0.878906 715.125 2.63672C718.079 4.39453 720.02 6.5918 720.947 9.22852C721.875 11.8408 722.339 15.918 722.339 21.46V27.0996Z" fill="white" />
                                    <path d="M758.923 1.24512V13.1104H749.768V60.5347H734.351V13.1104H725.232V1.24512H758.923Z" fill="white" />
                                </g>
                                <path id="dot1" d="M235.291 39C239.709 39 243.291 35.4183 243.291 31C243.291 26.5817 239.709 23 235.291 23C230.872 23 227.291 26.5817 227.291 31C227.291 35.4183 230.872 39 235.291 39Z" fill="#C9C9C9" />
                                <path id="dot2" d="M462.291 39C466.709 39 470.291 35.4183 470.291 31C470.291 26.5817 466.709 23 462.291 23C457.872 23 454.291 26.5817 454.291 31C454.291 35.4183 457.872 39 462.291 39Z" fill="#C9C9C9" />
                                <defs>
                                    <filter id="filter0_d" x="35.481" y="0" width="176.516" height="71.7798" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0.161 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                    </filter>
                                    <filter id="filter1_d" x="300.536" y="0" width="137.112" height="70.5347" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0.161 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                    </filter>
                                    <filter id="filter2_d" x="527.385" y="0" width="236.538" height="71.7798" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0.161 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        <div className="home-svg-container">
                            {/* <svg id="home-svg" xmlns="http://www.w3.org/2000/svg" width="1298" height="55" viewBox="0 0 1298 55">
                                <text id="home-quote" data-name="a house is made of walls and beams ；a home is built with love and dreams. " fill="#fff" font-size="35" font-family="Apple-Chancery, Apple Chancery"><tspan x="101.023" y="39">A house is made of walls and beams </tspan><tspan y="39" font-family="STSongti-SC-Regular, Songti SC">；</tspan><tspan y="39">A home is built with love and dreams. </tspan></text>
                            </svg> */}
                            <div id="home-svg" style={{ fontFamily: 'Apple-Chancery, Apple Chancery' }}>
                                <span id="home-quote">
                                    At ABC Prime, we aspire to always go beyond what has been done. We build communities <br/>
                                    that foster progress and nurture relationships, enabling us to strongly connect to Filipinos hopes and aspirations
                                </span>
                            </div>
                        </div>





                    </div>

                    <AnchorLink className="btn-explore" href="#creative-writings">
                        <span className="explore-ico">
                            <img className="arrow-area" src={arrowArea} alt=""></img>
                            <img className="arrow-down" src={arrowDown} alt=""></img>
                        </span>
                    </AnchorLink>
                </section>
                <section className="creative-writings" id="creative-writings">
                    {/* <h2 className="section-title">Creative Writings</h2> */}

                    <div className="slider-container">
                        <Slider {...sliderSettings}>
                            <div>
                                <div className="writing">
                                    <img src={slide1} alt="slide 1" />
                                    <div className="slide-desc">
                                        <h4 className="desc-title">QUALITY LIVING GUARANTEED</h4>
                                        <p className="desc">With ongoing efforts going beyond the traditional real estate boundaries, ABC PRIME delivers more than what is expected through innovations that will change the way Filipinos see home acquisitions.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="writing">
                                    <img src={slide2} alt="slide 2" />
                                    <div className="slide-desc">
                                        <h4 className="desc-title">Built-IN ATRATEGIC LOCATION</h4>
                                        <p className="desc">ABC PRIME’s presence will focus on strategic locations that will allow everyone to enjoy the proximity to everything they need, making it the top choice for those who deserve nothing but great quality.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="writing">
                                    <img src={slide3} alt="slide 3" />
                                    <div className="slide-desc">
                                        <h4 className="desc-title">DISTINCT ARCHITECTURAL DESIGN</h4>
                                        <p className="desc">By tapping best in class design partners and
innovators in the real estate industry, ABC PRIME will deliver only the best when it comes to residential, retail and commercial, business and other key lifestyle offerings.</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </section>
                <section className="awards-partners">
                    <h2 className="section-title">Awards</h2>
                    <div className="awards">
                        <div className="award">
                            <img src={awards} alt="Philippines Property Awards 2019" />
                            <div>
                                <h4>Winner</h4>
                                <p>BEST CONDO ARCHITECTURAL DESIGN(CEBU)</p>
                                <p>ARC TOWERS BY ABC PEIME INC.</p>
                            </div>
                        </div>
                        <div className="award">
                            <img src={awards} alt="Philippines Property Awards 2019" />
                            <div>
                                <h4>Highly Commended</h4>
                                <p>BEST AFFORDABLE CONDO DEVELOPMENT (CEBU)</p>
                                <p>ARC TOWERS BY ABC PEIME INC.</p>
                            </div>
                        </div>
                    </div>

                    <h2 className="section-title">Our Partners</h2>
                    <div className="partners">
                        <img src={lppaLogo} alt="LPPA Logo"></img>
                        <img src={broadwayLogo} alt="Broadway Malyan Logo"></img>
                        <img src={arcadisLogo} alt="Arcadis Logo"></img>
                        <img src={santosLogo} alt="Santos & Knight Frank Logo"></img>
                    </div>

                </section>
            </div >
        )
    }
}

export default Home;