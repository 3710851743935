import React, { Component } from 'react';

import aboutImg from '../assets/images/about-image.jpg';
import teamImg from '../assets/images/our-team-image.jpg';

const tabs = ["Our Team", "Our Company", "Our Business", "Mission & Vision", "Corporate Values"];

class About extends Component {

    state = {
        selectedTabIndex: 0
    }

    renderTeamTab() {
        return (
            <section>
                <h2 className="section-title left-aligned">Our Team</h2>
                <div className="desc">
                    <img src={teamImg} alt="Our Team" />
                    <div className="desc-details">
                        <p>We believe work should be fulfilling and talent should be recognized</p>
                        <p>Employees are encouraged to contribute their ideas, help the company be successful, and share their ideas with top management. Employees have plenty of opportunities to grow as a pioneer in our company.  We are pioneers and striving for continual innovation so that everything we develop looks and feels like nothing else in the market, and we promise to keep it that way. We treasure talented individuals, teamwork and the positive synergy they bring when working together, through their commitment and services. </p>
                        <p>We also firmly believe that our success comes from the diversity and strength of our workforce. Our people are our number one winning strategy - which is why we strive to attract and retain the very best the industry has to offer. </p>
                    </div>
                </div>
            </section>
        );
    }

    renderCompanyTab() {
        return (
            <section>
                <h2 className="section-title left-aligned">Our Company</h2>
                <div className="desc">
                    <div className="desc-details">
                        <p>ABC PRIME INC. is a real estate development company based in Cebu City, Philippines. Founded in 2017 and established in 2018 by a group of local entrepreneur and foreign investors, we develop world class residential and commercial properties that integrate tropical architecture in the design.</p>
                        <p>Our first flagship project is the ARC TOWERS - a high-rise, mixed-use development set in N. Bacalso Ave. cor. V. Rama, Cebu City, which recently received critical recognition and set the bar in the areas of architectural design and value-for-money.  With our contemporary style and expertise in connecting people with property, we are able to provide the ultimate real estate experience for today’s modern consumer.</p>
                    </div>
                </div>
            </section>
        )
    }

    renderBusinessTab() {
        return (
            <section>
                <h2 className="section-title left-aligned">Our Business</h2>
                <div className="desc">
                    <div className="desc-details">
                        <p>We at ABC Prime Inc. aim to elevate the overall quality of life in our local and foreign markets by building unique developments, destinations, and distinct value-for-money properties here in the Philippines as well as overseas. While we are primarily engaged in real estate development, property investment, sales and marketing condominiums, we are now  diversifying into office buildings, retail leasing, and hospitality industry. We also plan on  venturing into international venture and horizontal economic housing to able to further expedite our growth and expand our portfolio. All this is in service of our goal of advancing communities through global partnerships, which provide real value to our business, but most importantly, our customers.</p>
                    </div>
                </div>
            </section>
        )
    }


    renderMissionTab() {
        return (
            <section>
                <h2 className="section-title left-aligned">Mission & Vision</h2>
                <div className="desc">
                    <div className="desc-details">
                        <p>We envision ourselves as the leading commercial, retail, and residential real-estate developer that is top-of-mind when it comes to world-class properties. As forward-thinkers, we constantly innovate and develop residential and commercial properties that are masterfully-designed with sustainability in mind.</p>
                        <p>In partnership with the most trusted names in architectural design, we provide our clients with properties that galvanize the development and growth of communities.</p>
                    </div>
                </div>
            </section>
        )
    }


    renderValuesTab() {
        return (
            <section>
                <h2 className="section-title left-aligned">Corporate Values</h2>
                <div className="desc">
                    <div className="desc-details">
                        <p><b>ABC Prime Inc. (API) adheres and commits itself to the following values or principles:</b></p>

                        <h4>Excellence.</h4>
                        <p>ABC Prime Inc.’s work ethics emphasizes the pursuit of going beyond and above to deliver a fulfilling, impressive, and unforgettable experiences for its truly valued clientele and partners.</p>

                        <h4>Integrity.</h4>
                        <p>ABC Prime Inc. upholds consistency of corporate character, truthfulness, accuracy, authenticity, and “others-centeredness”.  It is continuously conscious of the impact of its corporate behavior on its clientele, partners, and the publics in general.</p>

                        <h4>Innovation.</h4>
                        <p>ABC Prime Inc.’s growth mindset defines its creative quest to continuously think out-of-the-box and raise the bar of the real estate industry.</p>

                        <h4>Social Responsibility.</h4>
                        <p>ABC Prime Inc. always strikes a balance between corporate growth and the welfare of the community and the environment.  Corporate decisions and actions are always ethically validated vis-à-vis the interests of society and the environment.  Moreover, ABC Prime Inc. shall always give back to the communities and exert efforts in improving the environmental footprint.</p>
                    
                        <h4>Sustainability.</h4>
                        <p>ABC Prime Inc. believes that open communications, collaborations, and partnerships are fundamental to institutional sustainability.  ABC Prime Inc. underscores the principle that sustainability is redefining business risk, to the point where the financial implications of a profitable business rest on a “triple bottom line of: Profit, People, and Planet.”</p>

                        <h4>Transformational Leadership.</h4>
                        <p>ABC Prime Inc.’s management envisages to inspire, to stimulate, and to energize its employees and partners towards achieving remarkable performances. </p>
                    </div>
                </div>
            </section>
        )
    }

    renderTab(tabIndex) {
        switch (tabIndex) {
            case 0:
                return this.renderTeamTab();
            case 1:
                return this.renderCompanyTab();
            case 2:
                return this.renderBusinessTab();
            case 3:
                return this.renderMissionTab();
            case 4:
                return this.renderValuesTab();
            default:
                return "";
        }
    }

    render() {
        const { selectedTabIndex } = this.state;
        return (
            <div className="about-page main-content">
                <section className="image-title">
                    <img src={aboutImg} alt="Development" />
                    <span className="title">About Us</span>
                </section>
                <ul className="tabs">
                    {
                        tabs.map((tab, index) => {
                            return <li className={(index === selectedTabIndex) ? "active" : ""}
                                onClick={() => this.setState({ selectedTabIndex: index })}>
                                {tab}
                            </li>
                        })
                    }
                </ul>

                <div className="about-content">
                    {this.renderTab(selectedTabIndex)}
                </div>
            </div>
        )
    }
}

export default About;